import { storage } from '@leanpay/local-storage'
import {
    AccountSingleStepPinRequest,
    AddressDetailsRequest,
    CancelTransactionRequest,
    CardPaymentPrepareRequest,
    CardPaymentResolvedRequest,
    ChangePinRequest,
    CheckoutData,
    ConnectionStatusRequest,
    ContractPreliminaryRequest,
    ContractSignOrBuyRequest,
    Country,
    CreatePinRequest,
    DocumentType,
    EmploymentInfoRequest,
    FinancialAccountNumberV2Request,
    FinancialDetailsOptionsTypes,
    FinancialDetailsRequestV2,
    FinancialProductV2Request,
    JWTLoginRequest,
    MaritalStatusRequest,
    Nationality,
    PaymentDataRequest,
    PersonMarketingConsentChangeRequest,
    PersonalDetailsCheckRequest,
    PersonalDetailsV2Request,
    ProfileAddressChangeRequest,
    ProfileIbanChangeRequest,
    Psd2ConnectionStatusRequest,
    Psd2TokenRequest,
    ReCreatePinRequest,
    RequestFinancialDataRequest,
    SessionInfo,
    Settlement,
    SubmitAdditionalDocumentValidationRequest,
    TransactionRequest,
    ValidationDataV2DTO,
    VendorRequestInvalidateRequest,
} from '@leanpay/models'
import {
    KYCSubmitCheckPayload,
    checkoutService,
    creditCardService,
    downloadService,
    kycService,
    logoutUser,
    psd2Service,
    saveBlobFile,
    setAuthToken,
    timeService,
} from '@leanpay/services'
import { STORAGE_KEY, SelectOptionOther, getCountryCodeBookId } from '@leanpay/utils'
import { useMutation, useQuery } from '@tanstack/react-query'

import { useAppStore } from 'app/app-context'

import {
    CacheKey,
    countryCodebookId,
    currentMarket,
    isMarketHun,
    isMarketSlo,
} from 'library/constants'

export const useGetVendorTokenCheckout = () => {
    const mutation = useMutation({
        mutationFn: (vendorMockToken: string) =>
            checkoutService.getVendorTokenCheckout(vendorMockToken),
    })

    return mutation
}

export const useGetCurrency = () => {
    return useQuery({
        queryKey: [CacheKey.CurrencyInfo],
        queryFn: () => checkoutService.getCurrencyFromServer(),
    })
}
export const useGetPaymentData = () => {
    const mutation = useMutation({
        mutationFn: (data: PaymentDataRequest) => {
            return checkoutService.getPaymentData(data)
        },
    })

    return mutation
}

export const useDownloadInitialOffer = () => {
    const mutation = useMutation({
        mutationFn: (data: TransactionRequest) => downloadService.downloadInitialOffer(data),
        onSuccess: (data: { blob: Blob; fileName: string }) =>
            saveBlobFile(data.blob, data.fileName),
    })

    return mutation
}
export const useDownloadPreliminaryContract = () => {
    const mutation = useMutation({
        mutationFn: (data: ContractPreliminaryRequest) =>
            downloadService.downloadPreliminaryContract(data),
        onSuccess: (data: { blob: Blob; fileName: string }) =>
            saveBlobFile(data.blob, data.fileName),
    })

    return mutation
}

export const useRequestPIN = () => {
    const { vendorToken } = useAppStore()
    const mutation = useMutation({
        mutationFn: async (data: AccountSingleStepPinRequest) => {
            return checkoutService.requestPIN(data)
        },
    })

    return mutation
}

export const useResendOtpOverInvoice = () => {
    const mutation = useMutation({
        mutationFn: (phoneNumber: string) => {
            return checkoutService.resendOtpOverVoice(phoneNumber)
        },
    })

    return mutation
}
export const useResendOtp = () => {
    const mutation = useMutation({
        mutationFn: (phoneNumber: string) => {
            return checkoutService.resendOtp(phoneNumber)
        },
    })

    return mutation
}

export const useUpdateIban = (twoFaPin: string) => {
    const { setSessionInfo } = useAppStore()
    const mutation = useMutation({
        mutationFn: (payload: ProfileIbanChangeRequest) =>
            checkoutService.ibanUpdate(payload, twoFaPin),
        onSuccess: async () => {
            const sessionInfo = await checkoutService.getSessionInfo()
            setSessionInfo(sessionInfo)
        },
    })

    return mutation
}
export const useUpdateAddress = () => {
    const { setSessionInfo } = useAppStore()

    const mutation = useMutation({
        mutationFn: (payload: ProfileAddressChangeRequest) =>
            checkoutService.addressUpdate(payload),

        onSuccess: async () => {
            const sessionInfo = await checkoutService.getSessionInfo()
            setSessionInfo(sessionInfo)
        },
    })

    return mutation
}
export const useUpdateMarketingConsent = () => {
    const { setSessionInfo } = useAppStore()
    const mutation = useMutation({
        mutationFn: (payload: PersonMarketingConsentChangeRequest) =>
            checkoutService.changeMarketingConsentLoginAction(payload),
        onSuccess: async () => {
            const sessionInfo = await checkoutService.getSessionInfo()
            setSessionInfo(sessionInfo)
        },
    })

    return mutation
}

export const useAuthenticate = () => {
    const { setSessionInfo, setIsAuthenticated } = useAppStore()
    const mutation = useMutation({
        mutationFn: async (data: JWTLoginRequest) => {
            return checkoutService.authenticate(data)
        },
        onSuccess: async (res) => {
            setAuthToken(res.idToken)
            const sessionInfo: SessionInfo = await checkoutService.getSessionInfo()
            setSessionInfo(sessionInfo)
            setIsAuthenticated(true)
        },
    })

    return mutation
}

export const useCheckPersonalDetails = () => {
    const mutation = useMutation({
        mutationFn: (data: PersonalDetailsCheckRequest) => {
            return checkoutService.checkPersonalDetails(data)
        },
    })
    return mutation
}
export const useSubmitPersonalDetails = () => {
    const mutation = useMutation({
        mutationFn: (data: PersonalDetailsV2Request) => {
            return checkoutService.submitPersonalDetails(data)
        },
    })

    return mutation
}
export const useSubmitAddressDetails = () => {
    const mutation = useMutation({
        mutationFn: (data: AddressDetailsRequest) => {
            return checkoutService.submitAddressDetailsV2(data)
        },
    })

    return mutation
}

export const useGetCountries = () => {
    return useQuery({
        queryKey: [CacheKey.Country],
        queryFn: () => checkoutService.getCountryCodebook(),
        select: (countries: any[]) => {
            const countryId = getCountryCodeBookId(currentMarket)
            const targetCountry = countries.find((c: Country) => c.id.toString() === countryId)
            const sortedCountries = targetCountry
                ? [
                      targetCountry,
                      ...countries.filter((c: Country) => c.id.toString() !== countryId),
                  ]
                : countries
            return sortedCountries
        },
    })
}

export const useGetIndustriesOfEmployment = () => {
    return useQuery({
        queryKey: [CacheKey.IndustryOfEmployment],
        queryFn: () => checkoutService.getIndustryCodebook(),
    })
}
export const useGetNationalities = () => {
    return useQuery({
        queryKey: [CacheKey.Nationality],
        queryFn: () => checkoutService.getNationalityCodebook(),
        select: (nationalities: any[]) => {
            const targetedNationality = nationalities.find(
                (n: Nationality) => n.country.toString().toUpperCase() === 'HUNGARY',
            )
            const sortedNationalites = targetedNationality
                ? [
                      targetedNationality,
                      ...nationalities.filter(
                          (n: Nationality) => n.country.toString().toUpperCase() !== 'HUNGARY',
                      ),
                  ]
                : nationalities
            return sortedNationalites
        },
    })
}

export const useLoadPersonalDetails = () => {
    const { vendorToken } = useAppStore()
    const mutation = useMutation({
        mutationFn: () => checkoutService.loadPersonalDetailsData(vendorToken),
    })

    return mutation
}

export const useLoadAddressDetails = () => {
    const { vendorToken } = useAppStore()
    return useQuery({
        queryKey: [CacheKey.AddressDetails],
        queryFn: async () => checkoutService.loadAddressDetails(vendorToken),
        retry: (failureCount, error) => {
            if (
                error === 'ADDRESS_DATA_DOES_NOT_EXIST' ||
                error === 'FINANCIAL_DATA_MISSING' ||
                error === 'MEMBERSHIP_DOES_NOT_EXIST'
            ) {
                return false
            }
            return true
        },
    })
}

export const useLoadFinancialDetails = () => {
    const { vendorToken } = useAppStore()
    return useQuery({
        queryKey: [CacheKey.FinancialDetails],
        queryFn: async () => checkoutService.loadFinancialDetails(vendorToken),
        retry: (failureCount, error) => {
            if (
                error === 'ADDRESS_DATA_DOES_NOT_EXIST' ||
                error === 'FINANCIAL_DATA_MISSING' ||
                error === 'MEMBERSHIP_DOES_NOT_EXIST'
            ) {
                return false
            }
            return true
        },
    })
}

export const useGetCurrentTime = () => {
    return useQuery({
        queryKey: [CacheKey.Time],
        queryFn: async () => timeService.getCurrentTime(),
    })
}

export const useGetSettlementsByStreet = (streetId: string) => {
    return useQuery({
        queryKey: [CacheKey.Settlement, streetId],
        enabled: !!streetId,
        refetchOnWindowFocus: true,

        queryFn: () => checkoutService.getSettlementsByStreet(streetId),
    })
}
export const useGetStreetsBySettlement = (settlementId: string) => {
    return useQuery({
        queryKey: [CacheKey.Streets, settlementId],
        enabled: !!settlementId,
        refetchOnWindowFocus: true,

        queryFn: () => checkoutService.getStreetsBySettlement(settlementId),
    })
}

export const useGetSettlements = () => {
    return useQuery({
        queryKey: [CacheKey.Settlement, countryCodebookId],
        enabled: !!countryCodebookId,
        queryFn: async () => {
            const settlements = await checkoutService.getSettlements(countryCodebookId)
            if (isMarketHun) {
                const data = [
                    ...settlements,
                    new Settlement({ id: 0, name: SelectOptionOther.OtherHUN, code: '0' }),
                ]
                return data
            }

            if (isMarketSlo) {
                // Remove duplicated items
                const uniqueItems = new Map()
                const updatedSettlements = settlements.filter((item) => {
                    if (!uniqueItems.has(item.name)) {
                        uniqueItems.set(item.name, true)
                        return true
                    }
                    return false
                })

                return updatedSettlements
            }

            return settlements
        },
    })
}
export const useGetSettlementsByPostZip = (postZipCode: string) => {
    return useQuery({
        enabled: !!postZipCode,
        queryKey: [CacheKey.Settlement, postZipCode],
        queryFn: () => checkoutService.getSettlementsByPostZip(postZipCode),
    })
}
export const useGetPostZipsByCountry = () => {
    return useQuery({
        queryKey: [CacheKey.PostZip],
        enabled: !!countryCodebookId,
        queryFn: () => checkoutService.getPostZips(countryCodebookId),
    })
}
export const useGetPostZipsBySettlement = (settlement: string) => {
    return useQuery({
        queryKey: [CacheKey.PostZip, settlement],
        enabled: !!settlement,
        queryFn: () => checkoutService.getPostZipsBySettlement(settlement),
    })
}
export const useGetStreetsByPostZip = (zipCode: string) => {
    return useQuery({
        queryKey: [CacheKey.Streets, zipCode],
        enabled: !!zipCode,
        queryFn: () => checkoutService.getStreetsByPostZip(zipCode),
    })
}

export const useRequestFinancialData = () => {
    const mutation = useMutation({
        mutationFn: (data: RequestFinancialDataRequest) =>
            checkoutService.requestFinancialData(data),
    })

    return mutation
}

export const useCreatePin = () => {
    const { setSessionInfo } = useAppStore()
    const mutation = useMutation({
        mutationFn: (payload: CreatePinRequest) => {
            return checkoutService.createPin(payload)
        },
        onSuccess: async () => {
            const sessionInfo = await checkoutService.getSessionInfo()
            setSessionInfo(sessionInfo)
        },
    })

    return mutation
}
export const useRecreatePin = () => {
    const { setSessionInfo } = useAppStore()
    const mutation = useMutation({
        mutationFn: (payload: ReCreatePinRequest) => checkoutService.recreatePin(payload),
        onSuccess: async () => {
            const sessionInfo = await checkoutService.getSessionInfo()
            setSessionInfo(sessionInfo)
        },
    })

    return mutation
}
export const useChangePin = () => {
    const mutation = useMutation({
        mutationFn: (payload: ChangePinRequest) => checkoutService.changePin(payload),
    })

    return mutation
}

export const useGetNumberOfConsecutivePaymentMonths = () => {
    return useQuery({
        queryKey: [CacheKey.NumberOfConsecutivePaymentMonths],
        queryFn: () =>
            checkoutService.getFinancialDetailsOptions(FinancialDetailsOptionsTypes.TYPE_1),
    })
}
export const useGetNumberOfRecentLoans = () => {
    return useQuery({
        queryKey: [CacheKey.NumberOfRecentLoans],
        queryFn: () =>
            checkoutService.getFinancialDetailsOptions(FinancialDetailsOptionsTypes.TYPE_2),
    })
}
export const useGetEmploymentTypes = () => {
    return useQuery({
        queryKey: [CacheKey.EmploymentType],
        queryFn: () => checkoutService.getEmploymentTypes(),
    })
}

export const useGetMaritalStatuses = () => {
    return useQuery({
        queryKey: [CacheKey.MaritalStatuses],
        queryFn: () => checkoutService.getMaritalStatuses(),
    })
}
export const useLoadMaritalStatus = () => {
    return useQuery({
        queryKey: [],
        queryFn: () => checkoutService.loadMaritalStatus(),
    })
}

export const useSubmitMartialStatus = () => {
    const mutation = useMutation({
        mutationFn: (payload: MaritalStatusRequest) => checkoutService.submitMaritalStatus(payload),
    })

    return mutation
}

export const useSubmitEmploymentInfo = () => {
    const mutation = useMutation({
        mutationFn: (payload: EmploymentInfoRequest) =>
            checkoutService.submitEmploymentInfo(payload),
    })

    return mutation
}

export const useValidateOfferV2 = () => {
    const { setSessionInfo } = useAppStore()
    const mutation = useMutation({
        mutationFn: (data: ValidationDataV2DTO) => {
            return checkoutService.validateCheckoutOfferV2(data)
        },
        onSuccess: async () => {
            const sessionInfo = await checkoutService.getSessionInfo()
            setSessionInfo(sessionInfo)
        },
    })

    return mutation
}

export const useGetContractData = () => {
    const mutation = useMutation({
        mutationFn: (payload: TransactionRequest) => checkoutService.getContractData(payload),
    })

    return mutation
}

export const useGetPepQuestionnaire = () => {
    return useQuery({
        queryKey: [CacheKey.PEPQuestionnaire],
        queryFn: () => checkoutService.getPepQuestionnaire(),
    })
}
export const useGetPepQuestionnaireROM = () => {
    return useQuery({
        queryKey: [CacheKey.PEPQuestionnaire],
        queryFn: () => checkoutService.getPepQuestionnaireROM(),
    })
}
export const useGetPepStatus = () => {
    const { vendorToken } = useAppStore()
    return useQuery({
        queryKey: [CacheKey.PepStatus],
        queryFn: () => checkoutService.getPepStatus(vendorToken),
    })
}

export const useGetSessionInfo = () => {
    const { vendorToken, setSessionInfo } = useAppStore()
    return useQuery({
        enabled: !!vendorToken,
        queryFn: () => checkoutService.getSessionInfo(),
        onSuccess: (data: SessionInfo) => {
            storage.save(STORAGE_KEY.SESSION_INFO, data)
            setSessionInfo(data)
        },
        refetchOnWindowFocus: false,
        retry: (count, error: any) => {
            if (error?.status === 401) {
                logoutUser()
                return false
            }
            return true
        },
    })
}

export const useSignOrBuy = () => {
    const mutation = useMutation({
        mutationFn: ({
            payload,
            twoFaPin,
        }: {
            payload: ContractSignOrBuyRequest
            twoFaPin?: string
        }) => checkoutService.signOrBuy(payload, twoFaPin),
    })

    return mutation
}

export const useDownloadGlobalDocument = (documentType: DocumentType) => {
    return useQuery({
        queryKey: [CacheKey.GlobalDocument, documentType],
        queryFn: () => downloadService.downloadGlobalDocument(documentType),
    })
}

export const useGetCreditCardStatus = (cardPaymentId: string) => {
    return useQuery({
        queryKey: [CacheKey.CreditCardStatus, cardPaymentId],
        queryFn: () => creditCardService.getCreditCardStatus(cardPaymentId),
    })
}

export const usePrepareCreditCard = () => {
    const mutation = useMutation({
        mutationFn: (payload: CardPaymentPrepareRequest) =>
            creditCardService.prepareCreditCard(payload),
    })

    return mutation
}

export const useResolvePaymentStatus = () => {
    const mutation = useMutation({
        mutationFn: (payload: CardPaymentResolvedRequest) =>
            creditCardService.resolveCreditCard(payload),
    })

    return mutation
}
export const useInvalidateVendorRequest = () => {
    const mutation = useMutation({
        mutationFn: (payload: VendorRequestInvalidateRequest) =>
            checkoutService.invalidateVendorRequest(payload),
    })

    return mutation
}

export const useGetSdkToken = () => {
    const { vendorToken } = useAppStore()
    const mutation = useMutation({
        mutationFn: () => kycService.getSdkToken(vendorToken),
    })

    return mutation
}

export const useKYCSubmitCheck = () => {
    const mutation = useMutation({
        mutationFn: (payload: KYCSubmitCheckPayload) => kycService.submitCheck(payload),
    })

    return mutation
}

export const useInitFinancialProduct = () => {
    const mutation = useMutation({
        mutationFn: (request: FinancialProductV2Request) =>
            checkoutService.financialProductInit(request),
    })

    return mutation
}
export const useCheckBankAccountNumber = () => {
    const mutation = useMutation({
        mutationFn: (request: FinancialAccountNumberV2Request) =>
            checkoutService.checkBankAccountNumber(request),
    })

    return mutation
}
export const useSubmitFinancialDetails = () => {
    const mutation = useMutation({
        mutationFn: (request: FinancialDetailsRequestV2) =>
            checkoutService.submitFinancialDetails(request),
    })

    return mutation
}

export const useGetExecutionAmounts = () => {
    return useQuery({
        queryKey: [CacheKey.ExecutionAmounts],
        queryFn: () => checkoutService.getExecutionAmounts(),
    })
}

//PSD2 - AIS HUN

export const useRequestPSD2Token = () => {
    const mutation = useMutation({
        mutationFn: (payload: Psd2TokenRequest) => psd2Service.requestToken(payload),
    })

    return mutation
}

export const useSendPSD2ConnectionStatus = () => {
    const mutation = useMutation({
        mutationFn: (payload: ConnectionStatusRequest) => psd2Service.sendConnectionStatus(payload),
    })
    return mutation
}

export const useGetPSD2ConnectionStatus = () => {
    const {
        setCheckoutData,
        setPaymentData,
        setSessionInfo,
        setVendorToken,
        setFinancialDetailsDataV2,
        setIsAuthenticated,
    } = useAppStore()

    const mutation = useMutation({
        mutationFn: (payload: Psd2ConnectionStatusRequest) =>
            psd2Service.getConnectionStatus(payload),
        onSuccess: async (res) => {
            const sessionInfoData = await checkoutService.getSessionInfo()
            if (res?.data) {
                const data = JSON.parse(res?.data)
                setCheckoutData(
                    new CheckoutData({
                        ...data.checkoutData,
                    }),
                )
                setIsAuthenticated(true)
                setSessionInfo(sessionInfoData)
                setVendorToken(data.token)
                setPaymentData(data.paymentData)
                setFinancialDetailsDataV2(data.financialDetailsDataV2)
                return
            }
        },
    })

    return mutation
}

export const useSubmitAdditionalDocument = () => {
    const mutation = useMutation({
        mutationFn: (data: SubmitAdditionalDocumentValidationRequest) => {
            return kycService.submitAdditionalDocument(data)
        },
    })

    return mutation
}

export const useCancelTransaction = () => {
    const mutation = useMutation({
        mutationFn: (data: CancelTransactionRequest) => {
            return checkoutService.cancelTransaction(data)
        },
    })

    return mutation
}
