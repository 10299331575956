export enum Market {
    SLO = 'SLO',
    HUN = 'HUN',
    ROM = 'ROM',
    CRO = 'CRO',
}

export enum Environment {
    LOCAL = 'LOCAL',
    TEST = 'TEST',
    STAGE = 'STAGE',

    PROD = 'PROD',
}

export enum Language {
    SLO = 'sl',
    ENG = 'en',
    ROM = 'ro',
    HUN = 'hu',
    CRO = 'hr',
}

export enum CountryCode {
    SLO = 'si',
    CRO = 'hr',
    HUN = 'hu',
    ROM = 'rom',
}

const second = 1000
const minute = 60 * second
const hour = 60 * minute

export enum CacheDuration {
    Short = 15 * minute,
    Medium = 60 * minute,
    Long = 6 * hour,
    UntilInvalidated = Infinity,
}

export enum STORAGE_KEY {
    AUTH_TOKEN = 'AUTH_TOKEN',
    SESSION_INFO = 'SESSION_INFO',
}

export enum IconName {
    Error = 'Error',
    Info = 'Info',
    Success = 'Success',
    Warning = 'Warning',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Timeout = 'Timeout',

    VerificationStatusError = 'VerificationStatusError',
    VerificationStatusReject = 'VerificationReject',
    VerificationStatusSuccess = 'VerificationSuccess',
    VerificationStatusTimeout = 'VerificationTimeout',
    VerificationStatusWarning = 'VerificationWarning',

    LeanPayTreeIcon = 'LeanPayTreeIcon',
    ShopCartIcon = 'ShopCartIcon',
    LockIcon = 'LockIcon',
    FAQIcon = 'FAQIcon',
    TooltipIcon = 'TooltipIcon',
    CheckboxCheckedIcon = 'CheckboxCheckedIcon',
    PlusCircleIcon = 'PlusCircleIcon',
    CheckIcon = 'CheckIcon',

    CloseCircleIcon = 'CloseCircleIcon',
    CloseIcon = 'CloseIcon',
    CalendarIcon = 'CalendarIcon',
    SmartphoneIcon = 'SmartphoneIcon',
    UserIcon = 'UserIcon',
    QuickCheckIcon = 'QuickCheckIcon',
    PowerByLeanPayIcon = 'PowerByLeanPayIcon',
    Identyum = 'Identyum',
    CheckMarkIcon = 'CheckMarkIcon',
    SisbonLogoIcon = 'SisbonLogoIcon',
    DownloadIcon = 'DownloadIcon',
    DocumentIcon = 'DocumentIcon',
    ContractIcon = 'ContractIcon',
    ContractSearchIcon = 'ContractSearchIcon',
    ArrowIcon = 'ArrowIcon',
    BurgerMenuIcon = 'BurgerMenuIcon',
    MasterCardIcon = 'MasterCardIcon',
    VisaIcon = 'VisaIcon',
    RingIcon = 'RingIcon',
    IdCardIcon = 'IdCardIcon',
    CreditCardIcon = 'CreditCardIcon',
    DashboardUserIcon = 'DashboardUserIcon',
    LeanPayTreeSecondaryIcon = 'LeanPayTreeSecondaryIcon',
    EmailIcon = 'EmailIcon',
    BackIcon = 'BackIcon',

    LogoutIcon = 'LogoutIcon',
    TransactionsIcon = 'TransactionsIcon',
    MobilePhoneIcon = 'MobilePhoneIcon',
    ShopBagIcon = 'ShopBagIcon',
    CalculatorIcon = 'CalculatorIcon',
    CheckedIcon = 'CheckedIcon',
    SettingsIcon = 'SettingsIcon',
    SettingsIconSecondary = 'SettingsIconSecondary',
    SpeedyClockIcon = 'SpeedyClockIcon',
    QuestionMarkSelfCheckoutIcon = 'QuestionMarkSelfCheckoutIcon',
    RedirectIcon = 'RedirectIcon',
    SearchIcon = 'SearchIcon',
    QuestionMarkIcon = 'QuestionMarkIcon',
    OffersIcon = 'OffersIcon',
    InvoiceIcon = 'InvoiceIcon',
    FilterIcon = 'FilterIcon',
    UserProfileIcon = 'UserProfileIcon',
    CookieIcon = 'CookieIcon',

    //Logos
    BigBangLogoIcon = 'BigBangLogoIcon',
    AliansaLogoIcon = 'AliansaLogoIcon',
    BralkoLogoIcon = 'BralkoLogoIcon',
    EltusLogoIcon = 'EltusLogoIcon',
    EmudiaLogoIcon = 'EmudiaLogoIcon',
    EplLogoIcon = 'EplLogoIcon',
    IdeoLogoIcon = 'IdeoLogoIcon',
    IstoreLogoIcon = 'IstoreLogoIcon',
    AddikoLogoIcon = 'AddikoLogoIcon',
    GbLogoIcon = 'GbLogoIcon',
    IonLogoIcon = 'IonLogoIcon',
    MedtLogoIcon = 'MedtLogoIcon',
    TrigalLogoIcon = 'TrigalLogoIcon',
    TriglavLogoIcon = 'TriglavLogoIcon',
    IStyleLogoIcon = 'IStyleLogoIcon',
}

export const daysInMonth = Array.from({ length: 31 }, (_, i) => `${i + 1}`).map((d) => {
    return { label: d, value: d }
})

export interface Option {
    label: string
    value: any
}

export interface Testimonial {
    personName: string
    text: string
}

export enum RestResponseKey {
    VENDOR_VERIFIED_CUSTOMER_REQUIRED = 'VENDOR_VERIFIED_CUSTOMER_REQUIRED',
    PRODUCT_VERIFIED_CUSTOMER_REQUIRED = 'PRODUCT_VERIFIED_CUSTOMER_REQUIRED',
    TAX_NUMBER_IS_WRONG = 'TAX_NUMBER_IS_WRONG',
    IBAN_IS_WRONG = 'IBAN_IS_WRONG',
    FAIL_CREDIT_CHECK = 'FAIL_CREDIT_CHECK',
    SCORING_FAILED = 'SCORING_FAILED',
    SERVICE_DISABLED = 'SERVICE_DISABLED',
    RESULTS_INVALID = 'RESULTS_INVALID',
    RESULTS_UNAVAILABLE = 'RESULTS_UNAVAILABLE',
    INTERNAL_APPLICATION_EXCEPTION = 'INTERNAL_APPLICATION_EXCEPTION',
    BAD_REQUEST = 'BAD_REQUEST',
    SESSION_DOES_NOT_EXIST = 'SESSION_DOES_NOT_EXIST',
    USERNAME_ALREADY_EXIST = 'USERNAME_ALREADY_EXIST',
    EMAIL_ALREADY_EXIST = 'EMAIL_ALREADY_EXIST',
    AGENT_WITH_PHONE_NUMBER_EXISTS = 'AGENT_WITH_PHONE_NUMBER_EXISTS',
    ACTIVATION_KEY_UNKNOWN = 'ACTIVATION_KEY_UNKNOWN',
    ACTIVATION_CODE_WRONG = 'ACTIVATION_CODE_WRONG',
    ACCOUNT_ALREADY_ACTIVATED = 'ACCOUNT_ALREADY_ACTIVATED',
    ACCOUNT_UNKNOWN = 'ACCOUNT_UNKNOWN',
    WRONG_PASSWORD = 'WRONG_PASSWORD',
    DOCUMENTATION_SIGNATURE_ERROR = 'DOCUMENTATION_SIGNATURE_ERROR',
    PATIENT_REGISTRATION_NOT_FOUND = 'PATIENT_REGISTRATION_NOT_FOUND',
    MULTIPLE_PATIENT_REGISTRATIONS = 'MULTIPLE_PATIENT_REGISTRATIONS',
    EMAIL_VERIFICATION_KEY_UNKNOWN = 'EMAIL_VERIFICATION_KEY_UNKNOWN',
    EMAIL_VERIFICATION_KEY_EXPIRED = 'EMAIL_VERIFICATION_KEY_EXPIRED',
    PASSWORD_RESET_KEY_UNKNOWN = 'PASSWORD_RESET_KEY_UNKNOWN',
    PASSWORD_RESET_KEY_EXPIRED = 'PASSWORD_RESET_KEY_EXPIRED',
    PASSWORD_NOT_VALID = 'PASSWORD_NOT_VALID',
    PHONE_NUMBER_ALREADY_EXIST = 'PHONE_NUMBER_ALREADY_EXIST',
    IDENTITY_NUMBER_ALREADY_EXIST = 'IDENTITY_NUMBER_ALREADY_EXIST',
    TAX_NUMBER_NOT_VALID = 'TAX_NUMBER_NOT_VALID',
    TAX_NUMBER_ALREADY_EXIST = 'TAX_NUMBER_ALREADY_EXIST',
    TAX_NUMBER_CHANGE_FORBIDDEN = 'TAX_NUMBER_CHANGE_FORBIDDEN',
    TAX_NUMBER_MISMATCH = 'TAX_NUMBER_MISMATCH',
    COUPON_NOT_VALID = 'COUPON_NOT_VALID',
    AMOUNT_FOR_COUPON_NOT_VALID = 'AMOUNT_FOR_COUPON_NOT_VALID',
    IDENTITY_CARD_NUMBER_ALREADY_EXIST = 'IDENTITY_CARD_NUMBER_ALREADY_EXIST',
    ADDRESS_CARD_NUMBER_ALREADY_EXIST = 'ADDRESS_CARD_NUMBER_ALREADY_EXIST',
    IDENTITY_CARD_NUMBER_DOES_NOT_EXIST = 'IDENTITY_CARD_NUMBER_DOES_NOT_EXIST',
    PHONE_NUMBER_DOES_NOT_EXIST = 'PHONE_NUMBER_DOES_NOT_EXIST',
    BIRTH_DATE_MISMATCH = 'BIRTH_DATE_MISMATCH',
    RESPONSIBILITY_ACCEPTED_EXCEPTION = 'RESPONSIBILITY_ACCEPTED_EXCEPTION',
    MEMBERSHIP_SUBMIT_NOT_VALID = 'MEMBERSHIP_SUBMIT_NOT_VALID',
    MEMBERSHIP_UPDATE_NOT_VALID = 'MEMBERSHIP_UPDATE_NOT_VALID',
    MEMBERSHIP_NOT_ASSIGNED = 'MEMBERSHIP_NOT_ASSIGNED',
    CREDIT_RATING_NOT_ASSIGNED = 'CREDIT_RATING_NOT_ASSIGNED',
    APPLICATION_DECLINED = 'APPLICATION_DECLINED',
    SALARY_NOT_CONFIRMED = 'SALARY_NOT_CONFIRMED',
    PERSON_BLACKLISTED = 'PERSON_BLACKLISTED',
    PERSON_KNOCKED_OUT = 'PERSON_KNOCKED_OUT',
    PERSON_KNOCKED_OUT_NEW = 'PERSON_KNOCKED_OUT_NEW',
    PERSON_KNOCKED_OUT_EXISTING = 'PERSON_KNOCKED_OUT_EXISTING',
    PERSON_MAX_EXPOSURE_CONSTRAINT_OCCURRED = 'PERSON_MAX_EXPOSURE_CONSTRAINT_OCCURRED',
    PERSON_MAX_ACTIVE_DUE_DAYS_ON_LP_CONSTRAINT_OCCURRED = 'PERSON_MAX_ACTIVE_DUE_DAYS_ON_LP_CONSTRAINT_OCCURRED',
    PERSON_PSD2_IBAN_35_CONSTRAINT_OCCURRED = 'PERSON_PSD2_IBAN_35_CONSTRAINT_OCCURRED',
    PERSON_IBAN_35_PROTECTED_CONSTRAINT_OCCURRED = 'PERSON_IBAN_35_PROTECTED_CONSTRAINT_OCCURRED',
    PERSON_CB_CURRENT_DPD = 'PERSON_CB_CURRENT_DPD',
    PERSON_MEMBERSHIP_IN_REJECTED_STATUS = 'PERSON_MEMBERSHIP_IN_REJECTED_STATUS',
    PERSON_ALREADY_HAS_PRE_APPROVED_TRANSACTION = 'PERSON_ALREADY_HAS_PRE_APPROVED_TRANSACTION',
    PERSON_DOES_NOT_EXIST = 'PERSON_DOES_NOT_EXIST',
    STUDENT_SECOND_TIME_SHOPPER_DISABLED = 'STUDENT_SECOND_TIME_SHOPPER_DISABLED',
    SECOND_IPHONE_PREMIUM_PROGRAM_PURCHASE_DISABLED = 'SECOND_IPHONE_PREMIUM_PROGRAM_PURCHASE_DISABLED',
    SECOND_LEANPAY_COUPON_PURCHASE_DISABLED = 'SECOND_LEANPAY_COUPON_PURCHASE_DISABLED',
    AMOUNT_NEGATIVE = 'AMOUNT_NEGATIVE',
    AMOUNT_ZERO = 'AMOUNT_ZERO',
    TRANSACTION_EXCEPTION = 'TRANSACTION_EXCEPTION',
    TRANSACTION_EXCEPTION_RATING_NOT_FOUND = 'TRANSACTION_EXCEPTION_RATING_NOT_FOUND',
    TRANSACTION_EXCEPTION_RATING_REJECTED = 'TRANSACTION_EXCEPTION_RATING_REJECTED',
    TRANSACTION_EXCEPTION_GLOBAL_LIMIT_EXCEEDED = 'TRANSACTION_EXCEPTION_GLOBAL_LIMIT_EXCEEDED',
    TRANSACTION_EXCEPTION_CLIENT_LIMIT_EXCEEDED = 'TRANSACTION_EXCEPTION_CLIENT_LIMIT_EXCEEDED',
    TRANSACTION_EXCEPTION_INSTALLMENT_LIMIT_EXCEEDED = 'TRANSACTION_EXCEPTION_INSTALLMENT_LIMIT_EXCEEDED',
    TRANSACTION_EXCEPTION_EOM_LIMIT_EXCEEDED = 'TRANSACTION_EXCEPTION_EOM_LIMIT_EXCEEDED',
    TRANSACTION_EXCEPTION_BAD_PERSON = 'TRANSACTION_EXCEPTION_BAD_PERSON',
    TRANSACTION_NOT_FOUND = 'TRANSACTION_NOT_FOUND',
    TRANSACTION_ALREADY_DELIVERED = 'TRANSACTION_ALREADY_DELIVERED',
    TRANSACTION_DELIVERY_FORBIDDEN = 'TRANSACTION_DELIVERY_FORBIDDEN',
    DISPUTE_UNKNOWN_TRANSACTION_ID = 'DISPUTE_UNKNOWN_TRANSACTION_ID',
    DISPUTE_ERROR = 'DISPUTE_ERROR',
    DISPUTE_NOT_FOUND_EXCEPTION = 'DISPUTE_NOT_FOUND_EXCEPTION',
    CLIENT_PAYMENT_PROCESSING_EXCEPTION = 'CLIENT_PAYMENT_PROCESSING_EXCEPTION',
    CLIENT_PAYMENT_REVERT_EXCEPTION = 'CLIENT_PAYMENT_REVERT_EXCEPTION',
    VENDOR_API_KEY_UNKNOWN = 'VENDOR_API_KEY_UNKNOWN',
    VENDOR_API_NULL_VENDOR_ID = 'VENDOR_API_NULL_VENDOR_ID',
    VENDOR_API_NULL_VENDOR_API_KEY = 'VENDOR_API_NULL_VENDOR_API_KEY',
    VENDOR_API_NULL_AMOUNT = 'VENDOR_API_NULL_AMOUNT',
    VENDOR_API_NULL_VENDOR_TRANSACTION_ID = 'VENDOR_API_NULL_VENDOR_TRANSACTION_ID',
    VENDOR_API_NULL_SUCCESS_URL = 'VENDOR_API_NULL_SUCCESS_URL',
    VENDOR_API_NULL_ERROR_URL = 'VENDOR_API_NULL_ERROR_URL',
    VENDOR_API_VENDOR_UNKNOWN = 'VENDOR_API_VENDOR_UNKNOWN',
    VENDOR_API_VENDOR_TRANSACTION_ID_DUPLICATED = 'VENDOR_API_VENDOR_TRANSACTION_ID_DUPLICATED',
    VENDOR_API_VENDOR_TRANSACTION_ID_UNKNOWN = 'VENDOR_API_VENDOR_TRANSACTION_ID_UNKNOWN',
    VENDOR_INACTIVE = 'VENDOR_INACTIVE',
    VENDOR_API_TOKEN_UNKNOWN = 'VENDOR_API_TOKEN_UNKNOWN',
    VENDOR_API_TOKEN_EXPIRED = 'VENDOR_API_TOKEN_EXPIRED',
    VENDOR_API_TOKEN_CANCELED = 'VENDOR_API_TOKEN_CANCELED',
    VENDOR_API_TOKEN_USED = 'VENDOR_API_TOKEN_USED',
    VENDOR_API_UNKNOWN_IP = 'VENDOR_API_UNKNOWN_IP',
    VENDOR_API_MIN_AMOUNT_EXCEEDED = 'VENDOR_API_MIN_AMOUNT_EXCEEDED',
    VENDOR_API_MAX_AMOUNT_EXCEEDED = 'VENDOR_API_MAX_AMOUNT_EXCEEDED',
    VENDOR_API_VALID_UNTIL_ERROR = 'VENDOR_API_VALID_UNTIL_ERROR',
    VENDOR_API_TERM_UNKNOWN = 'VENDOR_API_TERM_UNKNOWN',
    VENDOR_API_REQUEST_CANCEL_EXCEPTION = 'VENDOR_API_REQUEST_CANCEL_EXCEPTION',
    TOKEN_NOT_ASSIGNED_TO_CLIENT = 'TOKEN_NOT_ASSIGNED_TO_CLIENT',
    PASSPORT_ID_REGEX_ERROR = 'PASSPORT_ID_REGEX_ERROR',
    PERSONAL_DOCUMENT_ID_REGEX_ERROR = 'PERSONAL_DOCUMENT_ID_REGEX_ERROR',
    VENDOR_API_VERIFICATION_DOCUMENT_GET_FAILED = 'VENDOR_API_VERIFICATION_DOCUMENT_GET_FAILED',
    VENDOR_API_VERIFICATION_DOCUMENT_CREATE_FAILED = 'VENDOR_API_VERIFICATION_DOCUMENT_CREATE_FAILED',
    VENDOR_MISSING_DEFAULT_PRODUCT = 'VENDOR_MISSING_DEFAULT_PRODUCT',
    VENDOR_WRONG_PRODUCT_CODE = 'VENDOR_WRONG_PRODUCT_CODE',
    VENDOR_NO_IPHONE_PRODUCT = 'VENDOR_NO_IPHONE_PRODUCT',
    VENDOR_NO_IN_STORE_PRODUCT = 'VENDOR_NO_IN_STORE_PRODUCT',
    VENDOR_REMOTE_CUSTOM_OFFER_EXCEPTION = 'VENDOR_REMOTE_CUSTOM_OFFER_EXCEPTION',
    SUPPORT_TOPIC_UNKNOWN = 'SUPPORT_TOPIC_UNKNOWN',
    CREDIT_CARD_EXISTS = 'CREDIT_CARD_EXISTS',
    STRIPE_ERROR_GENERIC = 'STRIPE_ERROR_GENERIC',
    STRIPE_CHARGE_INVALID_AMOUNT = 'STRIPE_CHARGE_INVALID_AMOUNT',
    STRIPE_CHARGE_INVALID_REQUEST = 'STRIPE_CHARGE_INVALID_REQUEST',
    STRIPE_ERROR_INVALID_NUMBER = 'STRIPE_ERROR_INVALID_NUMBER',
    STRIPE_ERROR_INVALID_EXPIRY_MONTH = 'STRIPE_ERROR_INVALID_EXPIRY_MONTH',
    STRIPE_ERROR_INVALID_EXPIRY_YEAR = 'STRIPE_ERROR_INVALID_EXPIRY_YEAR',
    STRIPE_ERROR_INVALID_CVC = 'STRIPE_ERROR_INVALID_CVC',
    STRIPE_ERROR_INVALID_SWIPE_DATA = 'STRIPE_ERROR_INVALID_SWIPE_DATA',
    STRIPE_ERROR_INCORRECT_NUMBER = 'STRIPE_ERROR_INCORRECT_NUMBER',
    STRIPE_ERROR_EXPIRED_CARD = 'STRIPE_ERROR_EXPIRED_CARD',
    STRIPE_ERROR_INCORRECT_CVC = 'STRIPE_ERROR_INCORRECT_CVC',
    STRIPE_ERROR_INCORRECT_ZIP = 'STRIPE_ERROR_INCORRECT_ZIP',
    STRIPE_ERROR_CARD_DECLINED = 'STRIPE_ERROR_CARD_DECLINED',
    STRIPE_ERROR_MISSING = 'STRIPE_ERROR_MISSING',
    STRIPE_ERROR_PROCESSING_ERROR = 'STRIPE_ERROR_PROCESSING_ERROR',
    ACCOUNT_VERIFICATION_ERROR = 'ACCOUNT_VERIFICATION_ERROR',
    ACCOUNT_VERIFICATION_ALREADY_EXISTS_ERROR = 'ACCOUNT_VERIFICATION_ALREADY_EXISTS_ERROR',
    ACCOUNT_VERIFICATION_WRONG_CODE = 'ACCOUNT_VERIFICATION_WRONG_CODE',
    ACCOUNT_NOT_VERIFIED = 'ACCOUNT_NOT_VERIFIED',
    EMAIL_CHANGE_SAME_EMAIL = 'EMAIL_CHANGE_SAME_EMAIL',
    PHONE_NUMBER_CHANGE_SAME_NUMBER = 'PHONE_NUMBER_CHANGE_SAME_NUMBER',
    PHONE_NUMBER_VERIFY_ERROR = 'PHONE_NUMBER_VERIFY_ERROR',
    CONTACT_INFO_CHANGE_SAME_DATA = 'CONTACT_INFO_CHANGE_SAME_DATA',
    CONTACT_INFO_IDENTITY_NUMBER_CHANGE_FORBIDDEN = 'CONTACT_INFO_IDENTITY_NUMBER_CHANGE_FORBIDDEN',
    VENDOR_PRODUCT_CHANGE_SAME_DATA = 'VENDOR_PRODUCT_CHANGE_SAME_DATA',
    PATENT_PHONE_NUMBER_ALREADY_EXISTS = 'PATENT_PHONE_NUMBER_ALREADY_EXISTS',
    PATENT_NATIONAL_INSURANCE_NUMBER_ALREADY_EXISTS = 'PATENT_NATIONAL_INSURANCE_NUMBER_ALREADY_EXISTS',
    CAPITAL_POOL_RCR_ERROR = 'CAPITAL_POOL_RCR_ERROR',
    CAPITAL_POOL_COMMITTED_LESS_THAN_ZERO = 'CAPITAL_POOL_COMMITTED_LESS_THAN_ZERO',
    CAPITAL_POOL_VALUE_LESS_THAN_ZERO = 'CAPITAL_POOL_VALUE_LESS_THAN_ZERO',
    CANNOT_NPL_TRANSACTION = 'CANNOT_NPL_TRANSACTION',
    VENDOR_CUSTOM_REQUEST_AMOUNT_MAX_LIMIT_EXCEEDED = 'VENDOR_CUSTOM_REQUEST_AMOUNT_MAX_LIMIT_EXCEEDED',
    VENDOR_CUSTOM_REQUEST_AMOUNT_MIN_LIMIT_EXCEEDED = 'VENDOR_CUSTOM_REQUEST_AMOUNT_MIN_LIMIT_EXCEEDED',
    VENDOR_CUSTOM_REQUEST_MAX_INSTALLMENTS_LIMIT_EXCEEDED = 'VENDOR_CUSTOM_REQUEST_MAX_INSTALLMENTS_LIMIT_EXCEEDED',
    VENDOR_CUSTOM_REQUEST_VALIDITY_PERIOD_EXCEPTION = 'VENDOR_CUSTOM_REQUEST_VALIDITY_PERIOD_EXCEPTION',
    VENDOR_CUSTOM_REQUEST_CANCEL_EXCEPTION = 'VENDOR_CUSTOM_REQUEST_CANCEL_EXCEPTION',
    VENDOR_CUSTOM_REQUEST_PERSONAL_DATA_MISMATCH = 'VENDOR_CUSTOM_REQUEST_PERSONAL_DATA_MISMATCH',
    VENDOR_CUSTOM_REQUEST_VENDOR_PACKAGE_ERROR = 'VENDOR_CUSTOM_REQUEST_VENDOR_PACKAGE_ERROR',
    VENDOR_CUSTOM_REQUEST_IN_STORE_DATA_ERROR = 'VENDOR_CUSTOM_REQUEST_IN_STORE_DATA_ERROR',
    VENDOR_CUSTOM_REQUEST_IN_STORE_DATE_OF_EXPIRE_ERROR = 'VENDOR_CUSTOM_REQUEST_IN_STORE_DATE_OF_EXPIRE_ERROR',
    VENDOR_CUSTOM_REQUEST_ADDRESS_ERROR = 'VENDOR_CUSTOM_REQUEST_ADDRESS_ERROR',
    DISPUTE_APPROVE_EXCEPTION_PAYMENT_NOT_PROCESSED = 'DISPUTE_APPROVE_EXCEPTION_PAYMENT_NOT_PROCESSED',
    DISPUTE_APPROVE_EXCEPTION_PAYMENT_NOT_REVERTED = 'DISPUTE_APPROVE_EXCEPTION_PAYMENT_NOT_REVERTED',
    DISPUTE_REJECT_APPROVED_EXCEPTION = 'DISPUTE_REJECT_APPROVED_EXCEPTION',
    DISPUTE_CLOSE_EXCEPTION = 'DISPUTE_CLOSE_EXCEPTION',
    DISPUTE_PAYMENT_MATCH_EXCEPTION = 'DISPUTE_PAYMENT_MATCH_EXCEPTION',
    TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
    TRANSACTION_DELIVERY_NOT_VERIFIED_ERROR = 'TRANSACTION_DELIVERY_NOT_VERIFIED_ERROR',
    UPLOAD_LIMIT_EXCEEDED = 'UPLOAD_LIMIT_EXCEEDED',
    MSERVIS_RESPONSE_NOT_AVAILABLE = 'MSERVIS_RESPONSE_NOT_AVAILABLE',
    IDENTITY_CARD_ALREADY_EXIST = 'IDENTITY_CARD_ALREADY_EXIST',
    IBAN_BIC_NOT_VALID = 'IBAN_BIC_NOT_VALID',
    IBAN_NOT_VALID = 'IBAN_NOT_VALID',
    IBAN_GIRO_ACCOUNT = 'IBAN_GIRO_ACCOUNT',
    IBAN_BANK_UNKNOWN = 'IBAN_BANK_UNKNOWN',
    IBAN_IS_ALREADY_SET = 'IBAN_IS_ALREADY_SET',
    IBAN_ALREADY_EXIST = 'IBAN_ALREADY_EXIST',
    IBAN_BELONGS_TO_COMPANY = 'IBAN_BELONGS_TO_COMPANY',
    CONSENT_MARKETING_IS_ALREADY_SET = 'CONSENT_MARKETING_IS_ALREADY_SET',
    APPLICATION_NOT_FOUND = 'APPLICATION_NOT_FOUND',
    APPLICATION_ALREADY_IN_PACKAGE = 'APPLICATION_ALREADY_IN_PACKAGE',
    APPLICATION_APPROVAL_NOT_FOUND = 'APPLICATION_APPROVAL_NOT_FOUND',
    APPLICATION_APPROVAL_ALREADY_PENDING = 'APPLICATION_APPROVAL_ALREADY_PENDING',
    FINANCIAL_PRODUCT_INVALID_DATA = 'FINANCIAL_PRODUCT_INVALID_DATA',
    WRONG_PAYMENT_CYCLE_DATE = 'WRONG_PAYMENT_CYCLE_DATE',
    CREDIT_TRANSFER_PACKAGE_DIFFERENT_PAYMENT_CYCLE_DAYS = 'CREDIT_TRANSFER_PACKAGE_DIFFERENT_PAYMENT_CYCLE_DAYS',
    CREDIT_TRANSFER_PACKAGE_MAX_SIZE = 'CREDIT_TRANSFER_PACKAGE_MAX_SIZE',
    BANK_PAYMENT_ALREADY_PROCESSED = 'BANK_PAYMENT_ALREADY_PROCESSED',
    DOCUMENT_NOT_FOUND = 'DOCUMENT_NOT_FOUND',
    REQUEST_DUPLICATE = 'REQUEST_DUPLICATE',
    SISBON_SERVICE_ERROR = 'SISBON_SERVICE_ERROR',
    SISBON_SERVICE_RESOURCE_NOT_FOUND_ERROR = 'SISBON_SERVICE_RESOURCE_NOT_FOUND_ERROR',
    PRECONDITION_VALIDATION_EXCEPTION = 'PRECONDITION_VALIDATION_EXCEPTION',
    SISBON_OPERATOR_MISSING = 'SISBON_OPERATOR_MISSING',
    JOB_SCHEDULER_SERVICE_ERROR = 'JOB_SCHEDULER_SERVICE_ERROR',
    REPORTING_SERVICE_ERROR = 'REPORTING_SERVICE_ERROR',
    GB_DAILY_EXCHANGE_MANUAL_ERROR = 'GB_DAILY_EXCHANGE_MANUAL_ERROR',
    NOTIFICATION_SERVICE_ERROR = 'NOTIFICATION_SERVICE_ERROR',
    SCHEDULER_SERVICE_ERROR = 'SCHEDULER_SERVICE_ERROR',
    COMPANY_REGISTRY_SERVICE_ERROR = 'COMPANY_REGISTRY_SERVICE_ERROR',
    VENDOR_USER_AUTHORIZATION_ACTION_EXCEPTION = 'VENDOR_USER_AUTHORIZATION_ACTION_EXCEPTION',
    USER_AUTHORIZATION_ACTION_EXCEPTION = 'USER_AUTHORIZATION_ACTION_EXCEPTION',
    BAD_CREDENTIALS = 'BAD_CREDENTIALS',
    TOO_MANY_FAILED_LOGIN_ATTEMPTS = 'TOO_MANY_FAILED_LOGIN_ATTEMPTS',
    ADDRESS_PARAMS_NOT_VALID = 'ADDRESS_PARAMS_NOT_VALID',
    ADDRESS_IS_ALREADY_SET = 'ADDRESS_IS_ALREADY_SET',
    TWO_FA_PINS_DONT_MATCH = 'TWO_FA_PINS_DONT_MATCH',
    INVALID_FIELDS_FORMAT_VALIDATION = 'INVALID_FIELDS_FORMAT_VALIDATION',
    IBAN_OR_TAX_NUMBER_IS_WRONG = 'IBAN_OR_TAX_NUMBER_IS_WRONG',
    TWO_FA_PIN_ALREADY_SET = 'TWO_FA_PIN_ALREADY_SET',
    ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
    TWO_FA_PIN_EXPIRED = 'TWO_FA_PIN_EXPIRED',
    MEMBERSHIP_DOES_NOT_EXIST = 'MEMBERSHIP_DOES_NOT_EXIST',
    TWO_FA_IS_DISABLED = 'TWO_FA_IS_DISABLED',
    TWO_FA_IS_NOT_SET = 'TWO_FA_IS_NOT_SET',
    TWO_FA_CURRENT_AND_NEW_PIN_ARE_SAME = 'TWO_FA_CURRENT_AND_NEW_PIN_ARE_SAME',
    PEP_REQUEST_EMPTY_FOR_PEP_PERSON = 'PEP_REQUEST_EMPTY_FOR_PEP_PERSON',
    ON_DUTY_WORKING_HOURS_EXCEPTION = 'ON_DUTY_WORKING_HOURS_EXCEPTION',
    THIRD_PARTY_ERROR = 'THIRD_PARTY_ERROR',
    DAY_OF_BIRTHDAY_DOES_NOT_MATCH = 'DAY_OF_BIRTHDAY_DOES_NOT_MATCH',
    VERIFICATION_CODE_INCORRECT = 'VERIFICATION_CODE_INCORRECT',
    MEMBERSHIP_ALREADY_VERIFIED = 'MEMBERSHIP_ALREADY_VERIFIED',
    VERIFICATION_DOCUMENT_DOES_NOT_EXIST = 'VERIFICATION_DOCUMENT_DOES_NOT_EXIST',
    PARTNER_DOES_NOT_EXIST = 'PARTNER_DOES_NOT_EXIST',
    ANONYMOUS_USER_EXCEPTION = 'ANONYMOUS_USER_EXCEPTION',
    NOT_FOUND = 'NOT_FOUND',
    OPTIMISTIC_LOCK_EXCEPTION = 'OPTIMISTIC_LOCK_EXCEPTION',
    VENDOR_USER_WITH_EMAIL_EXIST = 'VENDOR_USER_WITH_EMAIL_EXIST',
    CARD_PAYMENT_ERROR = 'CARD_PAYMENT_ERROR',
    CARD_PAYMENT_STATUS_QUERY_NOT_AVAILABLE = 'CARD_PAYMENT_STATUS_QUERY_NOT_AVAILABLE',
    COLLECTION_VALIDATION_TRANSACTION_ALREADY_ASSIGNED = 'COLLECTION_VALIDATION_TRANSACTION_ALREADY_ASSIGNED',
    COLLECTION_VALIDATION_TRANSACTION_NOT_TRANSFERABLE = 'COLLECTION_VALIDATION_TRANSACTION_NOT_TRANSFERABLE',
    COLLECTION_VALIDATION_NO_TRANSACTIONS = 'COLLECTION_VALIDATION_NO_TRANSACTIONS',
    COLLECTION_VALIDATION_NO_PARTNER = 'COLLECTION_VALIDATION_NO_PARTNER',
    COLLECTION_VALIDATION_PACKAGE_NOT_DELETABLE = 'COLLECTION_VALIDATION_PACKAGE_NOT_DELETABLE',
    COLLECTION_VALIDATION_TRANSACTION_NOT_TRANSFERRED = 'COLLECTION_VALIDATION_TRANSACTION_NOT_TRANSFERRED',
    COLLECTION_VALIDATION_INVALID_STATUS_TRANSITION = 'COLLECTION_VALIDATION_INVALID_STATUS_TRANSITION',
    COLLECTION_INVALID_TRANSACTION = 'COLLECTION_INVALID_TRANSACTION',
    COLLECTION_VALIDATION_TRANSACTION_IS_NOT_ELIGIBLE_FOR_CONTRACT_CANCEL = 'COLLECTION_VALIDATION_TRANSACTION_IS_NOT_ELIGIBLE_FOR_CONTRACT_CANCEL',
    COLLECTION_VALIDATION_TRANSACTION_IS_NOT_CONTRACT_CANCEL = 'COLLECTION_VALIDATION_TRANSACTION_IS_NOT_CONTRACT_CANCEL',
    ADVANCED_PAYMENT_DUE_DATE_EXCEED = 'ADVANCED_PAYMENT_DUE_DATE_EXCEED',
    PASSPORT_INVALID_FORMAT = 'PASSPORT_INVALID_FORMAT',
    DRIVING_LICENSE_INVALID_FORMAT = 'DRIVING_LICENSE_INVALID_FORMAT',
    PERSONAL_ID_INVALID_FORMAT = 'PERSONAL_ID_INVALID_FORMAT',
    ADDRESS_CARD_ID_INVALID_FORMAT = 'ADDRESS_CARD_ID_INVALID_FORMAT',
    PERSON_MIN_INCOME_CONSTRAINT_OCCURRED = 'PERSON_MIN_INCOME_CONSTRAINT_OCCURRED',
    PERSON_MIN_VERIFIED_INCOME_CONSTRAINT_OCCURRED = 'PERSON_MIN_VERIFIED_INCOME_CONSTRAINT_OCCURRED',
    NO_PAYMENT_OPTIONS = 'NO_PAYMENT_OPTIONS',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    PENDING_SISBON_CHECK = 'PENDING_SISBON_CHECK',
    BANK_ACCOUNT_IDENTIFIER_IS_WRONG = 'BANK_ACCOUNT_IDENTIFIER_IS_WRONG',
    MOTHERS_MAIDEN_LAST_NAME_IS_WRONG = 'MOTHERS_MAIDEN_LAST_NAME_IS_WRONG',
    TAX_NUMBER_NOT_ALLOWED = 'TAX_NUMBER_NOT_ALLOWED',
    USER_AUTHENTICATION_WRONG_TOKEN = 'USER_AUTHENTICATION_WRONG_TOKEN',
}

export enum CardPaymentFailureReason {
    AUTHORIZATION_FAILED_3D_SECURE_MANDATORY = 'AUTHORIZATION_FAILED_3D_SECURE_MANDATORY',
    INVALID_CARD = 'INVALID_CARD',
    CVV2_FAILURE = 'CVV2_FAILURE',
    INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
    CARD_TYPE_NOT_SUPPORTED = 'CARD_TYPE_NOT_SUPPORTED',
    REFERENCED_TRANSACTION_FAILED_GENERAL_ERROR = 'REFERENCED_TRANSACTION_FAILED_GENERAL_ERROR',
    // NEW
    INCORRECT_CARD_NUMBER = 'INCORRECT_CARD_NUMBER',
    CARD_EXPIRED = 'CARD_EXPIRED',
    GENERAL_ERROR = 'GENERAL_ERROR',
}

export const sloTestimonials: Testimonial[] = [
    {
        personName: 'Miha Š.',
        text: 'Najboljše je, ker ti ni treba na banko niti nosit dokumentacije.',
    },
    {
        personName: 'Tadej J.',
        text: 'Na netu daš nekaj podatkov in takoj dobiš odobritev.',
    },
    {
        personName: 'Jelena L.',
        text: 'Vse lahko narediš na telefonu v par klikih.',
    },
    {
        personName: 'Matjaž H.',
        text: 'V aplikaciji vidiš vse na enem mestu in veš kaj si že plačal.',
    },
    {
        personName: 'Jerneja S.',
        text: 'Nobenih presenečenj, kar vidiš to dobiš.',
    },
    {
        personName: 'Luka C.',
        text: 'Ko sem se odločil, da vrnem nakup ni bilo problemov.',
    },
    {
        personName: 'Barbara V.',
        text: 'Takoj sem zvedla kak limit lahko dobim.',
    },
    {
        personName: 'Anže H.',
        text: 'Sprobal sem, da vidim kaj lahko dobim in moram rečt, da je zelo enostavno.',
    },
]

export const croTestimonials: Testimonial[] = [
    {
        personName: 'Barbara V.',
        text: 'Odmah sam znala koji limit mi je odobren.',
    },
    {
        personName: 'Luka C.',
        text: 'Kad sam odlučio vratiti robu koju sam kupio, nije bilo problema.',
    },
    {
        personName: 'Ana S.',
        text: 'Nema iznenađenja, ono što vidite to dobijete.',
    },
    {
        personName: 'Jelena L.',
        text: 'Na telefonu možete obaviti sve u nekoliko klikova.',
    },
    {
        personName: 'Matej J.',
        text: 'Unesete podatke u sustav i odmah dobijete odobrenje.',
    },
    {
        personName: 'Mihael Š.',
        text: 'Najbolje je što ne moram nikakve papire nositi u banku.',
    },
    {
        personName: 'Tina V.',
        text: 'Nema dodatnih ni skrivenih troškova, stvarno je kamata 0%.',
    },
    {
        personName: 'Matija H.',
        text: 'U aplikaciji vidite sve na jednom mjestu i znate što ste već platili i koliko možete potrošiti.',
    },
    {
        personName: 'Alen. H',
        text: 'Htio sam provjeriti kako izgleda ova aplikacija, i ugodno sam se iznenadio koliko je jednostavno.',
    },
    {
        personName: 'Ivana P.',
        text: 'Bez papira i kartica, jednostavno i glatko.',
    },
]

//Helper enum for select options in forms (HUN, SLO, ROM)
export enum SelectOptionOther {
    OtherHUN = 'Egyéb, a listában nem szereplő',
    OtherSLO = 'Drugo',
    OtherROM = 'Altul',
}

export enum Screen {
    Mobile = 'Mobile',
    Tablet = 'Tablet',
    Desktop = 'Desktop',
    LargeDesktop = 'LargeDesktop',
}
