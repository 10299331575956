import { PaymentPlan } from './PaymentPlan'

export class PaymentData {
    readonly purchaseAmount: number
    readonly paymentCycleDates: string[]
    readonly successUrl: string
    readonly errorUrl: string
    readonly siteUrl: string
    readonly paymentPlans: PaymentPlan[]
    readonly showCoupon: boolean
    readonly pendingApproval: boolean
    readonly pendingSisbonCheck: boolean
    readonly incomeLimit: number
    readonly graceEnabled: boolean
    readonly gracePaymentCycleDates: string[]

    constructor(data: any) {
        this.graceEnabled = data?.graceEnabled
        this.purchaseAmount = data?.purchaseAmount
        this.paymentCycleDates = data?.paymentCycleDates ? data.paymentCycleDates : []
        this.successUrl = data?.successUrl
        this.errorUrl = data?.errorUrl
        this.siteUrl = data?.siteUrl
        this.paymentPlans = data?.paymentPlans
            ? data.paymentPlans
                  .map((plan: any) => new PaymentPlan(plan))
                  .sort((a: PaymentPlan, b: PaymentPlan) => {
                      if (a.possible === b.possible) {
                          if (a.termMaturity.numberOfMonths > b.termMaturity.numberOfMonths)
                              return -1
                          if (a.termMaturity.numberOfMonths < b.termMaturity.numberOfMonths)
                              return 1
                          return 0
                      }
                      return a.possible ? -1 : 1
                  })
            : []

        this.showCoupon = data?.showCoupon
        this.pendingApproval = data?.pendingApproval
        this.pendingSisbonCheck = data?.pendingSisbonCheck
        this.incomeLimit = data?.incomeLimit
        this.gracePaymentCycleDates = data?.gracePaymentCycleDates
            ? data.gracePaymentCycleDates
            : []
    }
}
