import { HttpClient } from '@leanpay/http-client'
import {
    KYC_CHECK_RESULT_RESPONSE,
    SubmitAdditionalDocumentValidationRequest,
} from '@leanpay/models'

import { preQualifiedErrorHandler } from '../errorHandler'
import { clientInterceptor } from '../interceptors'

export enum KYC_DOCUMENT_TYPES {
    IDENTITY_CARD_FRONT_SIDE = 'IDENTITY_CARD_FRONT_SIDE',
    IDENTITY_CARD_BACK_SIDE = 'IDENTITY_CARD_BACK_SIDE',
    DRIVING_LICENCE_FRONT_SIDE = 'DRIVING_LICENCE_FRONT_SIDE',
    DRIVING_LICENCE_BACK_SIDE = 'DRIVING_LICENCE_BACK_SIDE',
    PASSPORT = 'PASSPORT',
    SELFIE = 'SELFIE',
    ADDRESS_CARD = 'ADDRESS_CARD',
}
export interface KYCSubmitCheckPayload {
    token: string
    documentIds?: {
        [key in keyof typeof KYC_DOCUMENT_TYPES]?: string
    }
}

class KycService {
    _httpClient = new HttpClient({
        config: { baseURL: undefined, responseType: 'json' },
        interceptorConfig: {
            onRequestFulfilled: clientInterceptor,
            onRejected: preQualifiedErrorHandler,
        },
    })
    private _onfidoSdkTokenUrl = 'api/kyc/token'
    private _kycCheckUrl = 'api/kyc/check'
    private _submitAdditionalDocumentUrl = 'api/kyc/additional-document'

    async getSdkToken(token: string) {
        const { data } = await this._httpClient.post<{ token: string }>(this._onfidoSdkTokenUrl, {
            token,
        })

        return data
    }

    async submitCheck(payload: KYCSubmitCheckPayload) {
        const { data } = await this._httpClient.post<{ token: string }>(this._kycCheckUrl, payload)

        return data
    }

    async getCheckStatus({ token }: { token: string }) {
        const { data } = await this._httpClient.get<KYC_CHECK_RESULT_RESPONSE>(
            `${this._kycCheckUrl}?token=${token}`,
        )
        return data
    }
    async submitAdditionalDocument(payload: SubmitAdditionalDocumentValidationRequest) {
        return await this._httpClient.post<{ token: string }>(
            this._submitAdditionalDocumentUrl,
            payload,
        )
    }
}

export const kycService = new KycService()
