import { isValid, parse } from 'date-fns'

import {
    CardPaymentFailureReason,
    CountryCode,
    Language,
    Market,
    RestResponseKey,
} from './constants'

export const isStringEqual = (str1: any, str2: any): boolean => {
    return str1.toString().trim().toLowerCase() === str2.toString().trim().toLowerCase()
}

export const truncate = (text: string, length: number): string => {
    if (!text) return ''
    if (text.length > length) {
        return `${text.substring(0, length)}...`
    }
    return text
}

export const getPhoneNumberValidationRegex = (countryCode: string, isProdEnvironment: boolean) => {
    const generalPhoneRegex = new RegExp(/^(\d{2}|\d{3})(\d{6}|\d{7}|\d{8})$/i)
    switch (countryCode) {
        case '386':
        case '381':
        case '385':
            return generalPhoneRegex
        case '40':
            if (isProdEnvironment) {
                return new RegExp(/^07\d{8}$/i)
            } else {
                return new RegExp(/^\d{9}$/i)
            }
        case '36':
            if (isProdEnvironment) {
                return new RegExp(/^(20|30|31|50|70|53)\d{7}$/)
            }
            return new RegExp(/^[0-9]*$/)
        default:
            return generalPhoneRegex
    }
}

export const separateThousands = (inputNumber: number): string => {
    const parts = inputNumber.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return parts.join('.')
}

export const getPhoneNumberPlaceholder = (countryCode: string, isProdEnvironment: boolean) => {
    switch (countryCode) {
        case '386':
        case '381':
        case '385':
            return '609999999'
        case '40':
            return isProdEnvironment ? '0700000000' : '700000000'
        case '36':
            return '20 123 4567'
        default:
            return ''
    }
}

export const getPhoneNumberMask = (countryCode: string, isProdEnvironment: boolean) => {
    switch (countryCode) {
        case '36':
            return '#########'
        case '381':
            return '###########'
        case '40':
            if (isProdEnvironment) {
                return '##########'
            } else {
                return '#########'
            }
        case '386':
            return '###########'

        default:
            return '###########'
    }
}

export const getCountryPhoneCodes = (market = Market.SLO, isProdEnvironment: boolean) => {
    switch (market) {
        case Market.CRO:
            return isProdEnvironment
                ? [{ label: '+385', value: '385' }]
                : [
                      { label: '+385', value: '385' },
                      { label: '+381', value: '381' },
                  ]

        case Market.SLO:
            return isProdEnvironment
                ? [{ label: '+386', value: '386' }]
                : [
                      { label: '+386', value: '386' },
                      { label: '+381', value: '381' },
                  ]
        case Market.ROM:
            return isProdEnvironment
                ? [{ label: '+40', value: '40' }]
                : [
                      { label: '+40', value: '40' },
                      { label: '+381', value: '381' },
                  ]
        case Market.HUN:
            return isProdEnvironment
                ? [{ label: '+36', value: '36' }]
                : [
                      { label: '+36', value: '36' },
                      { label: '+381', value: '381' },
                  ]

        default:
            return [
                { label: '+385', value: '385' },
                { label: '+381', value: '381' },
                { label: '+386', value: '386' },
            ]
    }
}

export const getCountryCodeBookId = (currentMarket: Market): string => {
    switch (currentMarket) {
        case Market.SLO:
            return '204'
        case Market.CRO:
            return '55'

        case Market.ROM:
            return '185'
        case Market.HUN:
            return '391'
        default:
            return '204'
    }
}

export const getCountryCode = (market: Market) => {
    if (Market.SLO === market) return CountryCode.SLO
    if (Market.ROM === market) return CountryCode.ROM
    if (Market.CRO === market) return CountryCode.CRO

    throw new Error(`Unknown market: ${market}`)
}

export const getBooleanFromString = (value?: string): boolean | null => {
    if (value === '') return null
    return value?.toLowerCase().trim() === 'true'
}

export const getDefaultAppLanguage = (market: Market): string => {
    if (Market.SLO === market) return Language.SLO
    if (Market.ROM === market) return Language.ROM
    if (Market.HUN === market) return Language.HUN
    if (Market.CRO === market) return Language.CRO

    return Language.SLO
}

export const isKnockoutError = (error: any) => {
    return (
        RestResponseKey.PERSON_KNOCKED_OUT === error ||
        RestResponseKey.PERSON_KNOCKED_OUT_NEW === error ||
        RestResponseKey.PERSON_KNOCKED_OUT_EXISTING === error ||
        RestResponseKey.PERSON_MAX_ACTIVE_DUE_DAYS_ON_LP_CONSTRAINT_OCCURRED === error ||
        RestResponseKey.PERSON_PSD2_IBAN_35_CONSTRAINT_OCCURRED === error ||
        RestResponseKey.PERSON_IBAN_35_PROTECTED_CONSTRAINT_OCCURRED === error
    )
}

export const isCardPaymentError = (error: string) => {
    return (
        error === RestResponseKey.CARD_PAYMENT_ERROR ||
        Object.keys(CardPaymentFailureReason).includes(error)
    )
}

export const trimBankNumberHun = (u: unknown) =>
    typeof u === 'string' ? u.split(' - ').join('').replace(/\s/g, '') : u

export const trimString = (u: unknown) => (typeof u === 'string' ? u.trim() : u)
export const trimStringWhiteSpace = (u: unknown) =>
    typeof u === 'string' ? u.replace(/\s+/g, '') : u

/**
 * Converts a string containing numeric characters to a number.
 * It removes common non-numeric characters like spaces, currency symbols,
 * and other symbols. If the input is not a string, it attempts to convert
 * it directly to a number. If the conversion is not possible, it returns
 * the original input.
 *
 * @param input The input value to be converted to a number.
 * @returns The converted number or the original input if conversion is not possible.
 */
export const trimStringToNumber = (input: any) => {
    if (typeof input === 'string') {
        // Remove spaces, currency symbols, and other non-numeric characters
        const cleanedInput = input.replace(/[\s,$€leiFtFT]/g, '')
        // Convert to number if the cleaned string is not empty
        return cleanedInput !== '' ? +cleanedInput : input
    } else {
        // Attempt to convert non-string input to a number
        return +input ? +input : input
    }
}
export const isDateValid = (dateString: string, dateFormat = 'dd/MM/yyyy') => {
    // Adjust the format for parsing based on dateFormat
    const adjustedFormat = dateFormat
        .replace(/yyyy/, 'yyyy')
        .replace(/MM/, 'MM')
        .replace(/dd/, 'dd')

    // Parse the date string using the specified format
    const date = parse(dateString, adjustedFormat, new Date())

    // Check if the parsed date is valid
    return isValid(date)
}

export const getIbanValidationOptions = (market: Market) => {
    if (market === Market.SLO) {
        return {
            length: 19,
            regex: '(^SI\\d{17})$',
        }
    } else if (market === Market.CRO) {
        return {
            length: 21,
            regex: '(^HR\\d{19})$',
        }
    }

    return
}

export const renderDecimalNumber = (value?: string | number, market?: Market) => {
    if (market === Market.HUN) {
        // First, ensure the value is a number
        const numericValue = typeof value === 'string' ? parseFloat(value) : value

        if (numericValue !== undefined) {
            // Use the toFixed method to always render two decimal places, then replace dots with commas
            return numericValue.toFixed(2).replace(/\./g, ',')
        }
    }

    if ((value && market === Market.ROM) || market === Market.SLO) {
        //Used for SLO, ROM
        const LOCALE = 'hr-HR'
        const formattedAmount = value?.toLocaleString(LOCALE, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })

        return formattedAmount
    }

    return value
}

export const scrollToFirstError = () => {
    const element = document.querySelector('.field-error')

    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
}

export const formatPhoneNumber = (input: string, countryCode: string) => {
    //HUN
    if (countryCode === '36') {
        const formatIndices = [2, 4, 7]

        const chars = Array.from(input)

        for (let i = formatIndices.length - 1; i >= 0; i--) {
            chars.splice(formatIndices[i], 0, ' ')
        }
        return chars.join('')
    }

    //ROM
    if (countryCode === '40') {
        return input
    }

    //SLO
    if (countryCode === '386') {
        // Extract the first two characters from the input
        const firstTwoChars = input.slice(0, 2)
        const restOfNumber = input.slice(2)

        // Format the number with spaces
        return `+${countryCode} ${firstTwoChars} ${restOfNumber}`
    }
    return `+${countryCode} ${input}`
}
