import axios, { AxiosError } from 'axios'

import { logoutUser } from './storage-service/storageService'

export interface ErrorResponse {
    entityName?: string
    errorKey?: string
    message: string
    path?: string
    detail?: string
    params?: any
    status: string
    title: string
    type: string
}

export const errorHandler = async (err: AxiosError<ErrorResponse>): Promise<any> => {
    const isAxiosErr = axios.isAxiosError(err)

    if (!isAxiosErr) return Promise.reject(err)

    const { status: axiosStatus, response } = err

    const status = axiosStatus || response?.status

    try {
        if (status === 500) return Promise.reject('Unable to complete request! Server unavailable')

        // If service is unavailable (maintenance mode) then reload page.
        if (status === 503) window.location.reload()

        if (err.code === 'ERR_NETWORK') {
            window.location.reload()
            return
        }

        // If not authenticated then auth token is expired and user will log out.
        if (status === 401 || status === 502 || status === 504) {
            logoutUser()
            return Promise.reject(`error.http.${status}`)
        }

        if (response) return Promise.reject(response?.data)

        return Promise.reject(err)
    } catch (e) {
        return Promise.reject('Unable to complete request')
    }
}

export const preQualifiedErrorHandler = async (err: AxiosError<any>): Promise<any> => {
    const isAxiosErr = axios.isAxiosError(err)

    if (!isAxiosErr) return Promise.reject(err)

    const { response } = err

    // If service is unavailable (maintenance mode) then reload page.
    if (response?.status === 503) {
        window.location.reload()
    }

    // If not authenticated then auth token is expired and user will log out.
    if (response?.status === 401 || response?.status === 502 || response?.status === 504) {
        logoutUser()
        return Promise.reject(response?.status)
    }
    // Get core app error code or default error message.
    const message =
        response?.headers['x-leanpaycoreapp-error'] ||
        response?.headers['x-leanpaycoreapp-defaultmessage']

    // Get core app error params.
    const params = response?.headers['x-leanpaycoreapp-params']

    if (params) {
        const error = {
            message: message || response?.status.toString(),
            params: JSON.parse(params),
        }

        return Promise.reject(error)
    }
    return Promise.reject(String(message || response?.status))
}

export const vendorErrorHandler = async (err: AxiosError<any>): Promise<any> => {
    const isAxiosErr = axios.isAxiosError(err)

    if (!isAxiosErr) return Promise.reject(err)

    const { response } = err

    // If service is unavailable (maintenance mode) then reload page.
    if (response?.status === 503) {
        window.location.reload()
    }

    // If not authenticated then auth token is expired and user will log out.
    if (response?.status === 401 || response?.status === 502 || response?.status === 504) {
        logoutUser()
        return Promise.reject(response?.status)
    }
    // Get core app error code or default error message.
    const message =
        response?.headers['x-leanpaycoreapp-error'] ||
        response?.headers['x-leanpaycoreapp-defaultmessage']

    // Get core app error params.
    const params = response?.headers['x-leanpaycoreapp-params']

    return Promise.reject(String(message || response?.status))
}
